var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "" } },
    [
      _c(
        "v-form",
        [
          _c(
            _setup.ValidationObserver,
            { ref: "obs" },
            [
              _c("header", { staticClass: "app-welcome-message" }, [
                !_setup.isAddingMasterMode && !_setup.isAddingDetailMode
                  ? _c("h2", [_vm._v("Details van gebouwgegevens")])
                  : _vm._e(),
                _setup.isAddingMasterMode
                  ? _c("h2", [_vm._v("Toevoegen van gebouwgegevens")])
                  : _vm._e(),
                _setup.isAddingDetailMode
                  ? _c("h2", [_vm._v("Toevoegen van gebouwgegevensversie")])
                  : _vm._e(),
              ]),
              _c(
                "v-container",
                {
                  staticClass: "details-button-footer-visible",
                  attrs: { fluid: "", "pa-0": "" },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-container",
                            { attrs: { fluid: "" } },
                            [
                              _c(
                                "v-row",
                                { attrs: { align: "center" } },
                                [
                                  _setup.hasDetails
                                    ? _c("v-col", { attrs: { cols: "auto" } }, [
                                        _vm._v(" Tijdlijn: "),
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-btn-toggle",
                                        {
                                          attrs: {
                                            group: "",
                                            mandatory: "",
                                            color: "blue accent-3",
                                          },
                                          model: {
                                            value: _setup.selectedTabIndex,
                                            callback: function ($$v) {
                                              _setup.selectedTabIndex = $$v
                                            },
                                            expression: "selectedTabIndex",
                                          },
                                        },
                                        _vm._l(
                                          _setup.detailTabs(),
                                          function (item, index) {
                                            return _c(
                                              "v-btn",
                                              {
                                                key: index,
                                                staticClass:
                                                  "v-application elevation-2",
                                                attrs: {
                                                  value: index,
                                                  disabled: !_setup.isReadOnly,
                                                },
                                              },
                                              [_vm._v(" " + _vm._s(item) + " ")]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "masonry",
                            {
                              attrs: {
                                cols: { default: 2, 1264: 1 },
                                gutter: 12,
                              },
                            },
                            [
                              _c(
                                "v-container",
                                {
                                  staticClass: "boxed",
                                  class: { noBorders: !_setup.isEditTLMode },
                                  attrs: { fluid: "" },
                                },
                                [
                                  _c("header", [_vm._v("Geldigheid")]),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "Geldig vanaf",
                                              rules: "date_between|required",
                                              mode: _setup.checkDateValid,
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c(_setup.DateInput, {
                                                      class: {
                                                        noBorders:
                                                          !_setup.allowEditTL,
                                                      },
                                                      attrs: {
                                                        "persistent-placeholder":
                                                          "",
                                                        label: "Geldig vanaf",
                                                        errors: errors,
                                                        outlined:
                                                          _setup.allowEditTL,
                                                        readonly:
                                                          !_setup.allowEditTL,
                                                        rules: "required",
                                                      },
                                                      model: {
                                                        value:
                                                          _setup.selectedData
                                                            .valFrom,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _setup.selectedData,
                                                            "valFrom",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "selectedData.valFrom",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "Geldig tot",
                                              rules: `date_between|date_after:${_setup.selectedData.valFrom}`,
                                              mode: _setup.checkDateValid,
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c(_setup.DateInput, {
                                                      class: {
                                                        noBorders:
                                                          !_setup.allowEditTL,
                                                      },
                                                      attrs: {
                                                        "persistent-placeholder":
                                                          "",
                                                        label: "Geldig tot",
                                                        errors: errors,
                                                        outlined:
                                                          _setup.allowEditTL,
                                                        readonly:
                                                          !_setup.allowEditTL,
                                                      },
                                                      model: {
                                                        value:
                                                          _setup.selectedData
                                                            .valTill,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _setup.selectedData,
                                                            "valTill",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "selectedData.valTill",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-container",
                                {
                                  staticClass: "boxed",
                                  class: {
                                    noBorders:
                                      !_setup.isEditDataMode &&
                                      !_setup.isEditTLMode,
                                  },
                                  attrs: { fluid: "" },
                                },
                                [
                                  _c("header", [_vm._v("Aanpassing")]),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "Aanpassings omschrijving",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      class: {
                                                        noBorders:
                                                          !_setup.allowEdit &&
                                                          !_setup.allowEditTL,
                                                      },
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        "persistent-placeholder":
                                                          "",
                                                        label: "Omschrijving",
                                                        "error-messages":
                                                          errors,
                                                        outlined:
                                                          _setup.allowEdit ||
                                                          _setup.allowEditTL,
                                                        readonly:
                                                          !_setup.allowEdit &&
                                                          !_setup.allowEditTL,
                                                      },
                                                      model: {
                                                        value:
                                                          _setup.selectedData
                                                            .modDescr,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _setup.selectedData,
                                                            "modDescr",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "selectedData.modDescr",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  !_setup.isAddingMasterMode &&
                                  !_setup.isAddingDetailMode
                                    ? _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                staticClass: "noBorders",
                                                attrs: {
                                                  "hide-details": "auto",
                                                  label: "Door",
                                                  readonly: "",
                                                },
                                                model: {
                                                  value:
                                                    _setup.selectedData.modBy,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _setup.selectedData,
                                                      "modBy",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "selectedData.modBy",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_setup.isAddingMasterMode &&
                                  !_setup.isAddingDetailMode
                                    ? _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-text-field", {
                                                staticClass: "noBorders",
                                                attrs: {
                                                  "hide-details": "auto",
                                                  label: "Datum",
                                                  readonly: "",
                                                },
                                                model: {
                                                  value:
                                                    _setup.modDateFormatted,
                                                  callback: function ($$v) {
                                                    _setup.modDateFormatted =
                                                      $$v
                                                  },
                                                  expression:
                                                    "modDateFormatted",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-container",
                                {
                                  staticClass: "boxed",
                                  class: { noBorders: !_setup.isEditTLMode },
                                  attrs: { fluid: "" },
                                },
                                [
                                  _c("header", [_vm._v("Bron")]),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "Brontype",
                                              rules: "required",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-select", {
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        label: "Type",
                                                        items:
                                                          _setup.sourceTypes,
                                                        "item-text":
                                                          "description",
                                                        "item-value": "key",
                                                        "error-messages":
                                                          errors,
                                                        readonly:
                                                          !_setup.isAddingMasterMode,
                                                      },
                                                      model: {
                                                        value:
                                                          _setup.selectedData
                                                            .sourceType,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _setup.selectedData,
                                                            "sourceType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "selectedData.sourceType",
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "masonry",
                            {
                              attrs: {
                                cols: { default: 2, 768: 1 },
                                gutter: 12,
                              },
                            },
                            [
                              _c(
                                "v-container",
                                { staticClass: "boxed", attrs: { fluid: "" } },
                                [
                                  _c("header", [_vm._v("Oppervlakte")]),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "Als: Verliesoppervlakte",
                                              rules:
                                                "decimal:2|between:0,9999.99",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      ref: "usableLossSurface",
                                                      class: {
                                                        noBorders:
                                                          !_setup.allowEdit,
                                                      },
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        type: "number",
                                                        step: "0.01",
                                                        label:
                                                          "Als: Verliesoppervlakte",
                                                        "persistent-placeholder":
                                                          "",
                                                        value:
                                                          _setup.selectedData
                                                            .usableLossSurface !=
                                                          undefined
                                                            ? Math.abs(
                                                                _setup
                                                                  .selectedData
                                                                  .usableLossSurface
                                                              )
                                                            : undefined,
                                                        "error-messages":
                                                          errors,
                                                        outlined:
                                                          _setup.allowEdit,
                                                        readonly:
                                                          !_setup.allowEdit,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.selectedData.usableLossSurface =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        [
                                          _c(_setup.ValidationProvider, {
                                            attrs: {
                                              name: "Als/Ag: Geometrieverhouding",
                                              rules: "decimal:2|between:0,100",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({ errors }) {
                                                  return [
                                                    _c("v-text-field", {
                                                      ref: "usableGeometry",
                                                      class: {
                                                        noBorders:
                                                          !_setup.allowEdit,
                                                      },
                                                      attrs: {
                                                        "hide-details": "auto",
                                                        type: "number",
                                                        step: "0.01",
                                                        label:
                                                          "Als/Ag: Geometrieverhouding",
                                                        "persistent-placeholder":
                                                          "",
                                                        value:
                                                          _setup.selectedData
                                                            .usableGeometry !=
                                                          undefined
                                                            ? Math.abs(
                                                                _setup
                                                                  .selectedData
                                                                  .usableGeometry
                                                              )
                                                            : undefined,
                                                        "error-messages":
                                                          errors,
                                                        outlined:
                                                          _setup.allowEdit,
                                                        readonly:
                                                          !_setup.allowEdit,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _setup.selectedData.usableGeometry =
                                                            $event !== ""
                                                              ? $event
                                                              : undefined
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "masonry",
                            { attrs: { cols: { default: 1 }, gutter: 12 } },
                            [
                              _c(
                                "v-container",
                                { staticClass: "boxed", attrs: { fluid: "" } },
                                [
                                  _c("header", [_vm._v("Soort")]),
                                  _vm._l(_setup.kinds, function (item, index) {
                                    return _c(
                                      "div",
                                      { key: item.key },
                                      [
                                        index > 0 ? _c("v-divider") : _vm._e(),
                                        _c(_setup.ConstructionKindInput, {
                                          attrs: {
                                            kindKey: item.key,
                                            description: item.description,
                                            boundaryTypes: _setup.boundaryTypes,
                                            loadedKinds: _setup.loadedKinds,
                                            insulationCategories:
                                              _setup.insulationCategories,
                                            editMode: _setup.allowEdit,
                                            partData: _setup.getPartData(
                                              item.key
                                            ),
                                            onlyCategories: false,
                                          },
                                          on: {
                                            "update-data": _setup.updatePart,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-footer",
                { staticClass: "details-buttons", attrs: { fixed: "" } },
                [
                  _c("details-buttons", {
                    attrs: {
                      addMode: _setup.isAddingMode,
                      editMode: !_setup.isReadOnly,
                      allowSave: !_setup.isSaving,
                    },
                    on: {
                      edit: _setup.setEditMode,
                      "edit-tl": _setup.setEditTLMode,
                      "on-cancel": _setup.onCancel,
                      "add-new-detail": _setup.addNewDetail,
                      "on-cancel-add": function ($event) {
                        return _setup.onCancelAdd()
                      },
                      "save-detail": _setup.saveDetail,
                      "save-new-detail": _setup.saveNewDetail,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }